import React, { useEffect, useState } from "react";
import { Menu, Tab } from "semantic-ui-react";

import { useIntl } from "react-intl";

import CardPreAnesthetic from "./CardPreAnesthetic";
import CardAnesRecord from "./CardAnesRecord";
import CardPACURecord from "./CardPACURecord";
import CardPeriDataSheet from "./CardPeriDataSheet";
import CardAnestheticComplication from "./CardAnestheticComplication";
import CardAnesBill from "./CardAnesBill";

const styles = {
  sideTabBar: {
    fontSize: 13.5,
    width: "105%",
  },
};

type CardAnestheticHistoryProps = {
  // seq
  runSequence?: any;
  PreAnestheticSequence?: any;
  selectedAnesthesiologist?: any;
  AnesRecordSequence?: any;
  HistoryCentralLabSequence?: any;
  AnestheticHistorySequence?: any;
  PACURecordSequence?: any;
  PeriDataSheetSequence?: any;
  AnesComplicationSequence?: any;
  AnesBillSequence?: any;
  // controller
  cardANSBillController?: any;
  // function
  onEvent: any;
  setProp: any;
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  languageUX?: any;
  DJANGO?: any;
  // options
  masterOptions?: any;
  masterData?: any;
  // SearchBox
  searchedItemListWithKey?: any;
  // data
  selectedDoctor?: any;
  encounterId?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;
  billDrugOrder?: any;
};

const CardAnestheticHistory = (props: CardAnestheticHistoryProps) => {
  const intl = useIntl();
  const [mainTabIndex, setMainTabIndex] = useState<number>(0);

  useEffect(() => {
    if (props.encounterId) {
      setMainTabIndex(0);
      props.runSequence({
        sequence: "AnestheticHistory",
        action: "SELECTED_ENCOUNTER",
        enId: props.encounterId,
        restart: true,
      });
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (props.AnestheticHistorySequence?.selectedOrOrder) {
      props.setProp("selectedOrOrder", props.AnestheticHistorySequence.selectedOrOrder);
    } else {
      props.setProp("selectedOrOrder", null);
    }
  }, [props.AnestheticHistorySequence?.selectedOrOrder]);

  // Tab
  const handleMainTabChange = (_event: any, { activeIndex }: any) => {
    setMainTabIndex(activeIndex);
  };

  return (
    <div>
      <Tab
        id="card-anes-history"
        activeIndex={mainTabIndex}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{
          vertical: true,
          style: styles.sideTabBar,
          className: "yellowHover",
        }}
        panes={[
          {
            menuItem: <Menu.Item key="PreAnesthetic">Pre Anes</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardPreAnesthetic
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // options
                  masterOptions={props.masterOptions}
                  masterData={props.masterData}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  PreAnestheticSequence={props.PreAnestheticSequence}
                  selectedDoctor={props.selectedDoctor}
                  selectedAnesthesiologist={props.selectedAnesthesiologist}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  HistoryCentralLabSequence={props.HistoryCentralLabSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  selectedEncounter={props.encounterId}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="AnesRecord">Anes Record</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardAnesRecord
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // options
                  masterOptions={props.masterOptions}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  AnesRecordSequence={props.AnesRecordSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="PACU">PACU Record</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardPACURecord
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  DJANGO={props.DJANGO}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  selectedEncounter={props.encounterId}
                  // options
                  masterOptions={props.masterOptions}
                  masterData={props.masterData}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  PACURecordSequence={props.PACURecordSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="PeriDataSheet">Peri Data Sheet</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardPeriDataSheet
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // options
                  masterOptions={props.masterOptions}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  PACURecordSequence={props.PACURecordSequence}
                  PeriDataSheetSequence={props.PeriDataSheetSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="AnesComplication">Anes Complication</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardAnestheticComplication
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // options
                  masterOptions={props.masterOptions}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  PACURecordSequence={props.PACURecordSequence}
                  AnesComplicationSequence={props.AnesComplicationSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: (
              <Menu.Item key="AnesBill">{intl.formatMessage({ id: "Anes ค่าใช้จ่าย" })}</Menu.Item>
            ),
            render: () => (
              <Tab.Pane>
                <CardAnesBill
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // controller
                  cardANSBillController={props.cardANSBillController}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  billDrugOrder={props.billDrugOrder}
                  selectedEncounter={props.encounterId}
                  selectedEmr={props.selectedEmr}
                  selectedProgressCycle={props.selectedProgressCycle}
                  // options
                  masterOptions={props.masterOptions}
                  // seq
                  isPHV={true}
                  runSequence={props.runSequence}
                  AnesBillSequence={props.AnesBillSequence}
                  selectedOrOrder={props.AnestheticHistorySequence?.selectedOrOrder}
                  languageUX={props.languageUX}
                />
              </Tab.Pane>
            ),
          },
        ]}
        onTabChange={handleMainTabChange}
      />
    </div>
  );
};

export default React.memo(CardAnestheticHistory);
